export const addUrlParams = (url, params) => {
	try {
		if (url === null) return;
		const urlObj = new URL(url);
		Object.keys(params).forEach((key) => {
			urlObj.searchParams.append(key, params[key]);
		});
		return urlObj.toString();
	} catch (e) {
		console.error('Error:', e);
		return;
	}
};

export function injectHtmlWithInlineJS(id, htmlContent, mergeData = {}) {
	// replace any string that is a single word wrapped in {{}} with the value of the same key in mergeData
	if (!htmlContent) return;
	const htmlContentWithMerge = htmlContent.replace(
		/{{\s*([a-zA-Z0-9_]+)\s*}}/g,
		(match, key) => {
			return mergeData[key] || '';
		}
	);

	// Create a temporary container to hold the HTML content
	const container = document.createElement('div');

	// See if the container already exists, in which case return
	const existing_div = document.getElementById(`webinar-cta-${id}`);
	if (existing_div) return;

	// Set the HTML content into the container
	container.setAttribute('id', `webinar-cta-${id}`);
	container.innerHTML = htmlContentWithMerge;

	document.body.appendChild(container);

	// Extract and execute all inline <script> tags
	const scripts = container.getElementsByTagName('script');
	for (const script of scripts) {
		const newScript = document.createElement('script');
		if (script.src) {
			newScript.src = script.src;
		} else {
			newScript.textContent = script.textContent;
		}

		try {
			document.head
				.appendChild(newScript)
				.parentNode.removeChild(newScript);
		} catch (e) {
			console.error('Error injecting script', e);
		}
	}
}

export async function sendInteraction(
	event,
	booking_id,
	webinar_id,
	additional_info = {},
	socket = null
) {
	let body;

	try {
		body = {
			event,
			booking_id,
			webinar_id,
			additional_info,
			device_type: window.deviceDetected?.device?.type,
			device_os: window.deviceDetected?.os?.family,
			device_browser: window.deviceDetected?.browser?.family,
			device_screen: `${window.innerWidth}x${window.innerHeight}`,
			device_info: window.deviceDetected
		};

		socket.emit('interaction', body);
	} catch (e) {
		console.error('Error sending interaction via socket', e, body);
	}
}
