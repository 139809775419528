import { create } from 'zustand';

const useWebinarStore = create((set) => ({
	activatedCtas: [],
	playedWelcome: false,
	addActivatedCtas: (ctaId) =>
		set((state) => ({ activatedCtas: [...state.activatedCtas, ctaId] }))
}));

export default useWebinarStore;
